import { HeadFC } from "gatsby";
import React, { useEffect } from "react";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";
import CallUsSidebarCTA from "../components/ctas/call-us-sidebar";
import FAQsWidget from "../components/faqs-widget/faqs-widget";
import Footer from "../components/footer/footer";
import Navigation from "../components/navigation/navigation";
import SEO from "../components/seo/seo";

// CSS
import StylesComponent from "../components/styles/styles-component";
import "../sass/page-specific/policy-styles.scss";

const FAQs: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="policy-hero-outer-container">
            <div className="policy-hero-container-overlay">
               <div className="policy-hero-text-container container-width">
                  <h1>Frequently Asked Questions (FAQs)</h1>
                  <p className="secondary-text">Find answers to some of our most frequently asked questions</p>
                  
                  <br/>

                  <a href="/contact" className="standard-button orange">Get in touch</a>
               </div>
            </div>
         </div>

         <div className="policy-content-outer-container">
            <div className="policy-content-grid-container container-width">
               <div className="main-content-container">
                  <Breadcrumbs location="faq"/>

                  <p>Find answers to the most of frequently asked questions (faqs) from our customer service team. If you can't find what you're looking for on this page, please get in touch with our team.</p>

                  <FAQsWidget />
               </div>

               <div className="content-sidebar-container">
                  <div className="sidebar-cta-wrapper">
                     <CallUsSidebarCTA/>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default FAQs

export const Head: HeadFC = () => (
   <SEO
      title="Frequently Asked Questions (FAQs) | Prestige VIP"
      metaDescription="Read up on some of our most frequently asked questions to solve some of your doubts before needing to get in touch."
      slug="/faq"
   />
)